import {NgModule} from '@angular/core';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavBarModule} from './shared/nav-bar/nav-bar.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {AuthModule} from './auth/auth.module';
import {LoginModule} from './login/login.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {SensorModule} from './sensor/sensor.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UnitModule} from './unit/unit.module';
import {ConfirmationService, MessageService} from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NavBarModule,
    AuthModule,
    LoginModule,
    DashboardModule,
    SensorModule,
    FontAwesomeModule,
    UnitModule,
    ToastModule
  ],
  providers: [
    ConfirmationService,
    MessageService,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
