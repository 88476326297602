import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagementService} from '../../../shared/api/endpoints/services/management.service';
import {ToastService} from '../../../shared/services/toast.service';

@Component({
  selector: 'app-unit-popup',
  templateUrl: './unit-popup.component.html',
  styleUrls: ['./unit-popup.component.scss']
})
export class UnitPopupComponent implements OnInit {

  insertForm: UntypedFormGroup;

  @Input() isVisible;
  @Input() unit;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private managementService: ManagementService,
    private toastService: ToastService
  ) {
    this.initForm();
  }

  initForm() {
    this.insertForm = this.formBuilder.group({
      unitDescription: ['', Validators.required]
    });
    setTimeout(() => {
      this.insertForm.controls.unitDescription.setValue(this.unit.description);
    }, 0);
  }

  ngOnInit(): void {
  }

  /**
   * Send insert unit request to backend and handle it
   */
  saveUnit() {
    if (this.insertForm.controls.unitDescription.value && this.insertForm.controls.unitDescription.value !== this.unit.description) {
      this.unit.description = this.insertForm.controls.unitDescription.value;
      this.managementService.updateUnit$Response({ body: {
        unit: {
            unit_id: this.unit.unitId,
            description: this.unit.description
          }}
      }).toPromise().then( response => {
        if (response.status === 200) {
          this.toastService.showSuccess();
          this.close();
        } else {
          this.toastService.showError(response.body);
        }
      }).catch(err => this.toastService.showError(err.body.message));
    }
  }

  close() {
    this.insertForm.reset();
    this.isVisibleChange.emit(false);
  }
}
