/* tslint:disable */
/* eslint-disable */
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OldObservation } from '../models/old-observation';
import { ExportStyle } from '../models/export-style';


/**
 * Observations endpoints
 */
@Injectable({
  providedIn: 'root',
})
export class ObservationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getObservation
   */
  static readonly GetObservationPath = '/SensorService?Operation=GetObservations';
  static readonly RestObservationsPath = '/rest/observation/';

  /**
   * Get observation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getObservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getObservation$Response(params: {
    unit_id: number;
    sensor_id: number;
    from?: string;
    to?: string;
  }): Observable<StrictHttpResponse<Array<OldObservation>>> {

    const rb = new RequestBuilder(this.rootUrl, ObservationService.GetObservationPath, 'get');
    if (params) {
      rb.query('unit_id', params.unit_id, {});
      rb.query('sensor_id', params.sensor_id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OldObservation>>;
      })
    );
  }

  /**
   * Get observation.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getObservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getObservation(params: {
    unit_id: number;
    sensor_id: number;
    from?: string;
    to?: string;
  }): Observable<Array<OldObservation>> {

    return this.getObservation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OldObservation>>) => r.body as Array<OldObservation>)
    );
  }

/**
   * Get export styles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExportStyle()` instead.
   *
   * This method doesn't expect any request body.
   */
 getExportStyle$Response(): Observable<StrictHttpResponse<Array<ExportStyle>>> {

  const rb = new RequestBuilder(this.rootUrl, ObservationService.RestObservationsPath + 'exportstyle', 'get');  

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ExportStyle>>;
    })
  );
}  

/**
   * Get observation.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExportStyle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
 getExportStyle(): Observable<Array<ExportStyle>> {

  return this.getExportStyle$Response().pipe(
    map((r: StrictHttpResponse<Array<ExportStyle>>) => r.body as Array<ExportStyle>)
  );
}


  exportObservations(params: {
    //group_id: number,
    sensor_id: number,
    from?: Date;
    to?: Date;
    exportStyleId: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ObservationService.RestObservationsPath + 'export', 'get');
    if (params) {
      rb.query('sensor_id', params.sensor_id);
      rb.query('from_time', formatDate(params.from, 'yyyy-MM-dd', 'en-US'));
      rb.query('to_time', formatDate(params.to, 'yyyy-MM-dd', 'en-US'));
      rb.query('style', params.exportStyleId);
      rb.query('nullable', false);
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
}
