<p-dialog [visible]="isVisible" [modal]="true" [closable]="false" [draggable]="false" header="Add unit"
          [baseZIndex]="10000" (onShow)="clearFormArray()" [className]="'popup-form'">

  <form [formGroup]="insertForm">
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-list-ol"></i></span>
      </div>
      <input type="text" formControlName="unitId" class="form-control" id="unitId" placeholder="Unit ID"/>
      <div *ngIf="insertForm.get('unitId').invalid &&
        insertForm.get('unitId').errors &&
        (insertForm.get('unitId').dirty || insertForm.get('unitId').touched)">
        <small class="text-danger"
               *ngIf="insertForm.get('unitId').hasError('required')">
          This field is required.
        </small>
      </div>
    </div>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-pen"></i></span>
      </div>
      <input type="text" formControlName="unitDescription" class="form-control" id="unitDescription"
             placeholder="Unit description"/>
      <div *ngIf="insertForm.get('unitDescription').invalid &&
        insertForm.get('unitDescription').errors &&
        (insertForm.get('unitDescription').dirty || insertForm.get('unitDescription').touched)">
        <small class="text-danger"
               *ngIf="insertForm.get('unitDescription').hasError('required')">
          This field is required.
        </small>
      </div>
    </div>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-globe"></i></span>
      </div>
      <input type="number" formControlName="lat" class="form-control" id="lat"
             placeholder="Latitude WGS-84 coordinates" step="0.0001" min="-90" max="90"/>
      <div *ngIf="insertForm.get('lat').invalid &&
        insertForm.get('lat').errors &&
        (insertForm.get('lat').dirty || insertForm.get('lat').touched)">
        <small class="text-danger"
               *ngIf="insertForm.get('lat').hasError('required')">
          This field is required.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lat').hasError('min')">
          The minimum value for this field is -90.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lat').hasError('max')">
          The minimum value for this field is 90.
        </small>
      </div>
    </div>
    <div class="input-group form-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-globe"></i></span>
      </div>
      <input type="number" formControlName="lon" class="form-control" id="lon"
             placeholder="Longitude WGS-84 coordinates" step="0.0001" min="-180" max="180"/>
      <div *ngIf="insertForm.get('lon').invalid &&
        insertForm.get('lon').errors &&
        (insertForm.get('lon').dirty || insertForm.get('lon').touched)">
        <small class="text-danger"
               *ngIf="insertForm.get('lon').hasError('required')">
          This field is required.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lon').hasError('min')">
          The minimum value for this field is -180.
        </small>
        <small class="text-danger"
               *ngIf="insertForm.get('lon').hasError('max')">
          The minimum value for this field is 180.
        </small>
      </div>
    </div>
    <h4>Added sensors</h4>
    <hr>
    <div class="input-group form-group popup-sensors-wrapper">
      <div formArrayName="sensors" *ngFor="let item of insertForm.get('sensors')['controls']; let i = index;">
        <div [formGroupName]="i" class="popup-sensor">
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-list-ol"></i></span>
            </div>
            <input type="text" formControlName="sensorId" class="form-control" id="sensorId" placeholder="Sensor ID"/>
          </div>
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-file-signature"></i></span>
            </div>
            <input type="text" formControlName="sensorName" class="form-control" id="sensorName"
                   placeholder="Sensor name"/>
          </div>
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-file-signature"></i></span>
            </div>
            <select formControlName="sensorType" id="sensorType">
              <option value="null" disabled>Select sensor type</option>
              <option *ngFor="let sensorType of sensorTypes; let i = index" [value]="sensorTypes[i].sensorType">
                {{sensorTypes[i].sensorType}}
              </option>
            </select>
          </div>
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-tint"></i></span>
            </div>
            <select formControlName="phenomenons" id="phenomenons">
              <option value="null" disabled>Select phenomenon</option>
              <option *ngFor="let phenomenon of phenomenons; let i = index" [value]="phenomenons[i].phenomenonId">
                {{phenomenons[i].phenomenonName}} ( {{ phenomenons[i].unit}})
              </option>
            </select>
          </div>
        </div>
        <br>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="popup-buttons-wrapper">
      <div class="popup-buttons-group">
        <button pButton type="button" label="Add sensor" class="p-button-success" (click)="addSensor()"></button>
        <button pButton type="button" *ngIf="sensors > 0"  label="Remove last sensor" class="p-button-danger" (click)="removeSensor()"></button>
      </div>
      <div class="popup-buttons-group">
        <button pButton type="button" label="Close" class="p-button-primary dark" icon="pi pi-times" (click)="close()"></button>
        <button pButton type="submit" label="Save" class="p-button-primary dark" icon="pi pi-check" (click)="processInsertion()"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
