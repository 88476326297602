<div class="navbar-wrapper container-fluid">
  <div class="container">
    <h1>SensLog</h1>
    <nav class="navbar navbar-expand-lg navbar-light">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <a class="navbar-brand mobile" href="/">
        <img src="/assets/images/senslog-logo.svg" alt="Logo SensLog">
      </a>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav left">
          <!--<li class="nav-item">
            <a class="nav-link" [routerLink]="['/dashboard']"><h2>Dashboard</h2></a>
          </li>-->
          <li *ngIf="loggedUser?.userInfo?.rightsId == 0" class="nav-item">
            <a class="nav-link" id="addUser" (click)="addUser()"><h2><i class="fas fa-user-plus"></i>&nbsp;Add user</h2></a>
          </li>
          <li *ngIf="loggedUser?.userInfo?.rightsId == 0 || loggedUser?.userInfo?.rightsId == 1" class="nav-item">
            <a class="nav-link" id="addUnit" (click)="insertUnitPopup()"><h2><i class="fas fa-folder-plus"></i>&nbsp;Add unit</h2></a>
          </li>
          <li *ngIf="loggedUser?.userInfo?.rightsId == 0 || loggedUser?.userInfo?.rightsId == 1" class="nav-item">
            <a class="nav-link" id="downloadData" (click)="downloadData()"><h2><i class="fas fa-download"></i>&nbsp;Data download</h2></a>
          </li>
        </ul>
        <a class="navbar-brand desktop" href="/">
          <img src="/assets/images/senslog-logo.svg" alt="Logo SensLog">
        </a>
        <ul class="navbar-nav right">
          <li class="nav-item nav-link-user">
            <h2 id="loggedUser"><i class="fas fa-user"></i>&nbsp;<strong>{{loggedUser?.userInfo?.userName.toUpperCase()}}</strong></h2>
          </li>
          <li class="nav-item">
            <a class='nav-link' id="logOut" [routerLink]="['/login']" (click)="logOut()"><h2><i class="fas fa-sign-out-alt"></i>&nbsp;Logout</h2></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

<app-user-insert-popup *ngIf="showAddUserPopup" [(isVisible)]="showAddUserPopup"></app-user-insert-popup>
<app-unit-insert-popup [(isVisible)]="showInsertUnitPopup" [phenomenons]="phenomenons" (emitNewUnit)="addUnit($event)" [sensorTypes]="sensorTypes"></app-unit-insert-popup>
<app-data-download-popup [(isVisible)]="showDataDownloadPopup"></app-data-download-popup>
