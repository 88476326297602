import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {GroupService} from '../../../api/endpoints/services/group.service';
import {Group} from '../../../api/endpoints/models/group';
import {AdministrationService} from '../../../api/endpoints/services/administration.service';
import {Right} from '../../../api/endpoints/models/right';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {ToastService} from '../../../services/toast.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-insert-popup',
  templateUrl: './user-insert-popup.component.html',
  styleUrls: ['./user-insert-popup.component.scss']
})
export class UserInsertPopupComponent implements OnInit, OnDestroy {

  groups: Group[];
  rights: Right[];
  insertForm: UntypedFormGroup;
  subscription: Subscription[] = [];
  @Input() isVisible;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private groupService: GroupService,
    private administrationService: AdministrationService,
    private toastService: ToastService
  ) {
    this.initForm();
    this.getGroups();
  }

  ngOnInit(): void {
  }

  /**
   * Unsubscribe after leaving
   */
  ngOnDestroy(): void {
    this.subscription.forEach(subs => subs.unsubscribe());
  }

  getGroups() {
    this.groupService.getGroups({Operation: 'GetGroups'}).pipe(
      tap(data => this.groups = data)
    ).subscribe();
  }

  getRights() {
    this.administrationService.getRights().pipe(
      tap(data => this.rights = data)
    ).subscribe();
  }

  /**
   * Close popup
   */
  close() {
    this.isVisibleChange.emit(false);
  }

  /**
   * If valid form send request with new user to server
   */
  saveUser() {
    if (this.insertForm.valid) {
      this.administrationService.createUser$Response({body: {
        userPass: this.insertForm.controls.userPass.value,
        userName: this.insertForm.controls.userName.value,
        rightsId: this.insertForm.controls.rights.value,
        userRealName: this.insertForm.controls.userRealName.value,
        groupId: this.insertForm.controls.groups.value
        }
      }).pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            this.close();
            this.toastService.showSuccess();
          } else {
            this.toastService.showError(null);
          }
        })
      ).toPromise().then().catch(err => this.toastService.showError(err.error));
    }
  }

  initForm() {
    this.getRights();
    this.insertForm = this.formBuilder.group({
      userName: ['', Validators.required],
      userPass: ['', Validators.required],
      userRealName: ['', Validators.required],
      groups: ['', Validators.required],
      rights: ['', Validators.required]
    });
  }
}
