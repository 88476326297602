import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../../shared/api/endpoints/services';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private router: Router,
  ) {}

  canActivate(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole: string[] = routeSnapshot.data.expectedRole;
    let user = this.authService.getUser();
    let userInfo = user.userInfo;
    if (expectedRole.includes(userInfo?.rightsId.toString()) || expectedRole.includes(user.userCookie.rightsID.toString())) {
      console.log('You have enough rights!');
      return true;
    }
    console.log('You are not allowed to be here!');
    this.router.navigate(['']);
    return false;
  }
}
