import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitComponent } from './components/unit.component';
import { NavBarModule } from '../shared/nav-bar/nav-bar.module';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';



@NgModule({
  declarations: [UnitComponent],
  imports: [
    CommonModule,
    NavBarModule,
    RouterModule,
    CalendarModule,
    FormsModule,
    ListboxModule,
    CheckboxModule,
    AccordionModule
  ]
})
export class UnitModule { }
