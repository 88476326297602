import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {NavBarComponent} from './components/nav-bar.component';
import {RouterModule} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import { UserInsertPopupComponent } from './components/user-insert-popup/user-insert-popup.component';
import {DialogModule} from 'primeng/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import { UnitInsertPopupComponent } from './components/unit-insert-popup/unit-insert-popup.component';
import { DataDownloadPopupComponent } from './components/data-download/data-download-popup.component';
import { CalendarModule } from 'primeng/calendar';
import { ProgressBarModule } from 'primeng/progressbar';
import { ListboxModule } from 'primeng/listbox';

@NgModule({
  declarations: [NavBarComponent, UserInsertPopupComponent, UnitInsertPopupComponent, DataDownloadPopupComponent],
  exports: [
    NavBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule,
    CalendarModule,
    ListboxModule,
    ProgressBarModule
  ]
})
export class NavBarModule {
}
