<app-nav-bar></app-nav-bar>

<div class="container graph">

  <button pButton type="button" label="Back to Dashboard" class="p-button-primary" icon="pi pi-backward" [routerLink]="['/dashboard']" style="margin-bottom: 15px;"></button>

  <div class="graph-information">
    <div class="graph-desc">
      <div>
        <span class="graph-attr-heading">Unit description: </span>
        {{unitDescription}}
      </div>
    </div>
    <div class="graph-range-dates">
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-color-date background-date-color"><i class="fa fa-calendar-alt" aria-hidden="false"></i></span>
        </div>
        <p-calendar id="from" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="from" [showTime]="true" (onSelect)="onDateChanged()" [maxDate]="today" showButtonBar="true"></p-calendar>
      </div>
      <div class="graph-range-dates-separator">
        <div></div>
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-color-date background-date-color"><i class="fa fa-calendar-alt" aria-hidden="false"></i></span>
        </div>
        <p-calendar id="to" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="to" [showTime]="true" (onSelect)="onDateChanged()" [maxDate]="today" showButtonBar="true"></p-calendar>
      </div>      
      <div>
        <button pButton type="button" *ngIf="dateChanged" label="Load data" class="p-button-primary" icon="pi pi-chart-line" (click)="showGraph()"></button>
      </div>
    </div>
    <div *ngIf="showIntervalError" class="alert alert-danger" role="alert">
      Select data from interval smaller than <b>6 months</b>!
    </div>
  </div>

  <p-accordion [multiple]="true" class="graph-view-wrapper">
    <p-accordionTab *ngFor="let group of sensorGroups">

      <ng-template pTemplate="header">        
        <div class="graph-group-heading">          
          <ng-container *ngFor="let sensorType of sensorTypes">
            <ng-container *ngIf="sensorType.sensorId.toString().slice(0, 5) === group">
              {{sensorType.sensorType}}
            </ng-container>
          </ng-container>
          sensors
        </div>
      </ng-template>
      
      <ng-template pTemplate="content">

        <div class="d-inline-flex">
          <div *ngFor="let sensor of sensors">
            <div *ngIf="sensor.sensorId.toString().slice(0, 5) === group">
              <p-checkbox class="align-text-top" [id]="sensor.sensorId" name="{{group}}" [value]="sensor.sensorId.toString()" [(ngModel)]="selectedSensors" [inputId]="sensor.sensorId.toString()" (onChange)="addSensorToGraph(sensor.sensorId.toString(), $event)"></p-checkbox>
              <label >{{sensor.sensorName}}</label>
            </div>
          </div>
        </div>
        
        
        <div id="vega_container_{{group}}"></div>
      </ng-template>

    </p-accordionTab>
  </p-accordion>

</div>
