/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable, throwError } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';

import { UserCookie } from '../models/user-cookie';
import { UserInfo } from '../models/user-info';


/**
 * Login process
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation login
   */
  static readonly LoginPath = '/ControllerServlet';

  /**
   * Authorize login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method doesn't expect any request body.
   */
  login$Response(params?: {

    /**
     * User login
     */
    username?: string;

    /**
     * User password
     */
    password?: string;
  }): Observable<StrictHttpResponse<UserCookie>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.LoginPath, 'post');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserCookie>;
      })
    );
  }

  /**
   * Authorize login.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  login(params?: {

    /**
     * User login
     */
    username?: string;

    /**
     * User password
     */
    password?: string;
  }): Observable<UserCookie> {

    return this.login$Response(params).pipe(
      map((r: StrictHttpResponse<UserCookie>) => r.body as UserCookie)
    );
  }

  /**
   * Path part for operation getUserInfo
   */
  static readonly GetUserInfoPath = '/rest/user';

  /**
   * User information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfo$Response(params?: {
  }): Observable<StrictHttpResponse<UserInfo>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.GetUserInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      }),
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfo>;
      })
    );
  }

  /**
   * User information.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfo(params?: {
  }): Observable<UserInfo> {

    return this.getUserInfo$Response(params).pipe(
      map((r: StrictHttpResponse<UserInfo>) => r.body as UserInfo)
    );
  }

}
