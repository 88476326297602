import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagementService} from '../../../shared/api/endpoints/services/management.service';
import * as moment from 'moment-timezone';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ToastService} from '../../../shared/services/toast.service';
import {SensorsService} from '../../../shared/api/endpoints/services/sensors.service';

@Component({
  selector: 'app-position-insert-popup',
  templateUrl: './position-insert-popup.component.html',
  styleUrls: ['./position-insert-popup.component.scss']
})
export class PositionInsertPopupComponent implements OnInit {

  insertForm: UntypedFormGroup;

  @Input() isVisible;
  @Input() unitId;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sensorService: SensorsService,
    private toastService: ToastService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
  }
  initForm() {
    this.insertForm = this.formBuilder.group({
      lat: ['', [Validators.required, Validators.min(-90), Validators.max(90)]],
      lon: ['', [Validators.required, Validators.min(-180), Validators.max(180)]],
      alt: '',
      speed: '',
      dop: ''
    });
  }

  clearFormArray() {
    this.insertForm.reset();
  }

  /**
   * Send insert position request to backend adn handle response.
   */
  processInsertion() {
    if (this.insertForm.valid) {
      const lat = this.insertForm.controls.lat.value;
      const lon = this.insertForm.controls.lon.value;
      const alt = this.insertForm.controls.alt.value;
      const speed = this.insertForm.controls.speed.value;
      const dop = this.insertForm.controls.dop.value;

      this.sensorService.insertPosition$Response( {
        lat, lon, alt, speed, dop, unit_id: this.unitId, date: moment().format('yyyy-MM-DD HH:mm:ssZZ')
      }).pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            this.toastService.showSuccessMessage('Position to unit ' + this.unitId + ' inserted!');
            this.close();
          } else {
            this.toastService.showError('Position insertion caused error!');
          }
        })
      ).toPromise().then().catch(err => this.toastService.showError(err.error.message));
    }
  }

  /**
   * Close popup
   */
  close() {
    this.isVisibleChange.emit(false);
  }
}
