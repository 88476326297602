import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ManagementService} from '../../../shared/api/endpoints/services/management.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ToastService} from '../../../shared/services/toast.service';
import {Sensor} from '../../../shared/api/endpoints/models/sensor';
import {SensorType} from '../../../shared/api/endpoints/models/sensor-type';

@Component({
  selector: 'app-sensor-popup',
  templateUrl: './sensor-popup.component.html',
  styleUrls: ['./sensor-popup.component.scss']
})
export class SensorPopupComponent implements OnInit {

  insertForm: UntypedFormGroup;

  @Input() isVisible;
  @Input() sensor: Sensor;
  @Input() phenomenons;
  @Input() unitId;
  @Input() sensorTypes: SensorType[];
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private managementService: ManagementService,
    private toastService: ToastService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
  }

  /**
   * Close popup
   */
  close() {
    this.isVisibleChange.emit(false);
  }

  initForm() {
    console.log(this.sensorTypes);
    this.insertForm = this.formBuilder.group({
      sensorName: ['', Validators.required],
      sensorType: ['', Validators.required],
      phenomenon: ['', Validators.required]
    });
    setTimeout(() => {
      this.insertForm.controls.phenomenon.setValue(this.sensor.phenomenon.phenomenonId, {onlySelf: true});
      this.insertForm.controls.sensorType.setValue(this.sensor.sensorType, {onlySelf: true});
      this.insertForm.controls.sensorName.setValue(this.sensor.sensorName);
    }, 0);
  }

  /**
   * Send update sensor request to backend. Creates object from form input. Handle response.
   */
  processSensorEdition() {
    if (this.insertForm.valid) {
      this.managementService.updateSensor$Response({body: {
        unit: {
          unit_id: this.unitId
        },
          sensors: [
            {
              sensor_id: this.sensor.sensorId,
              sensor_type: this.insertForm.controls.sensorType.value,
              sensor_name: this.insertForm.controls.sensorName.value,
              phenomenon: {
                phenomenon_id: this.insertForm.controls.phenomenon.value
              }
            }
          ]
        }}).pipe(
       map((response: HttpResponse<any>) => {
         if (response.status === 200) {
           this.toastService.showSuccessMessage('Sensor ' + response.body.sensors[0].sensorName + ' updated!');
           this.changeSensor();
           this.close()
         } else {
         }
       })
     ).toPromise().then().catch(err => this.toastService.showError(err.error.message));
    }
  }

  /**
   * Change sensor data to memory
   */
  changeSensor() {
    this.sensor.sensorType = this.insertForm.controls.sensorType.value;
    this.sensor.sensorName = this.insertForm.controls.sensorName.value;
    this.sensor.phenomenon.phenomenonId = this.insertForm.controls.phenomenon.value;
  }
}
