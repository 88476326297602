import {Component, OnDestroy, OnInit} from '@angular/core';
import {Group} from '../../shared/api/endpoints/models/group';
import {Drivers} from '../../shared/api/endpoints/models/drivers';
import {GeneralInfo} from '../../shared/api/endpoints/models/general-info';
import {Lastpos} from '../../shared/api/endpoints/models/lastpos';
import {Sensor} from '../../shared/api/endpoints/models/sensor';
import {Unit} from '../../shared/api/endpoints/models/unit';
import {DataService} from '../../shared/api/endpoints/services/data.service';
import {Phenomenon} from '../../shared/api/endpoints/models/phenomenon';
import {SensorsService} from '../../shared/api/endpoints/services/sensors.service';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {ManagementService} from '../../shared/api/endpoints/services/management.service';
import {ToastService} from '../../shared/services/toast.service';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {AuthService} from '../../auth/services/auth.service';
import {User} from '../../auth/models/user';
import {SensorType} from '../../shared/api/endpoints/models/sensor-type';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  loggedUser: User;
  position: 'bottom';
  groups: Group[];
  units: Array<{ drivers?: Drivers; generalInfo?: GeneralInfo; holder?: any; lastpos?: Lastpos; sensors?: Array<Sensor>; unit?: Unit }>;
  editedUnit: Unit;
  showEditUnitPopup = false;
  showInsertSensorPopup = false;
  showInsertPositionPopup = false;
  //phenomenons: Phenomenon[];
  sensorTypes: SensorType[];
  subscription: Subscription[] = [];

  constructor(
    private dataService: DataService,
    private sensorService: SensorsService,
    //private confirmationService: ConfirmationService,
    //private messageService: MessageService,
    //private managementService: ManagementService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    this.initData();
  }

  ngOnInit(): void {
  }

  /**
   * Unsubscribe after leaving
   */
  ngOnDestroy(): void {
    this.subscription.forEach(subs => subs.unsubscribe());
  }

  /**
   * Get necessary data from backend
   */
  initData() {
    //this.sensorService.getPhenomenons().subscribe(
    //  response => this.phenomenons = response
    //);
    this.sensorService.getSensorTypes().subscribe(
      response => this.sensorTypes = response
    );
    this.setUser();
    this.getUnits();
  }

  /**
   * Get user from user state
   */
  setUser(){
    this.authService.getUserState().subscribe(res => {
      if(res){
        this.loggedUser = res;
      }
    });
  }

  /**
   * Get all units and theirs sensors from backend
   */
  getUnits() {
    this.dataService.getData().subscribe(data => {
      this.units = data;
      this.units.forEach(unit => unit.sensors.sort((a, b) => a.sensorId - b.sensorId));
    }, err => this.toastService.showError(err.error.message));
  }

  /**
   * Add created unit to memory so we do not need call backend
   * @param inserted unit
   */
  addUnit(inserted: any) {
    const sensors: Sensor[] = [];
    inserted.sensors.forEach(sens => {
      sensors.push({
        sensorId: sens.sensor_id,
        sensorType: sens.sensor_type,
        sensorName: sens.sensor_name,
        phenomenon: {
          phenomenonId: sens.phenomenon.phenomenon_id.toString()
        }
      })
    });
    this.units.push({
      unit: {
        unitId: inserted.unit.unit_id,
        description: inserted.unit.description
      },
      sensors
    })
  }
}
